/*
=========================================================
* Otis Kit PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function Information() {
  return (
    <MKBox component="section" py={6} mt={6}>
      <Container>
        <Grid container spacing={3} item xs={12} lg={8} mx="auto">
          
          <MKTypography variant="h2" mb={3}>
           כיצד המערכת עובדת ?
          </MKTypography>
          <MKTypography variant="body2">
         
                                לאחר פתיחת במחלוקת חדשה מערכת קונפליקט תיצור עבורך קשר עם הצד השני ותזמין אותו למערכת קונפליקט.
                                <br/>

                                לאחר עיון בפרטי המחלוקת ניתנת לשני הצדדים אפשרות להגיש הצעות פשרה בהתאם לאינטרסים האישיים של כל צד.
                                <br/>

                                לאחר הגשת הצעתך לפשרה במחלוקת המערכת מעדכנת את הנתונים ותמתין עד להגשת הפשרה של הצד השני בתיק.
                                <br/>

                                במידה ותהיה התאמה בין שתי ההצעות תפיק המערכת את מחיר הפשרה אשר יהיה מבוסס על ההצעות שניתנו ע&quot;י שני הצדדים.
                                <br/>

                                במידה ולא תהיה התאמה בפשרה המערכת תעדכן את הצדדים שהצעה נכשלה וניתנת לשני הצדדים אפשרות להגיש שוב הצעות פשרה.
                                <br/>

                                אם לאחר 5 ניסיונות לא הצליחו הצדדים להגיע לפשרה, מציעה המערכת מחיר פשרה אוטומטי לאישור שני הצדדים על ידי ייחודיות המערכת ביכולות הפענוח של חוכמת ההמונים שלה להתחקות אחר הצעות הצדדים ולספק לצדדים הצעת פשרה אופטימאלית.
                                <br/>

                                במידה והצדדים לא יסכימו למחיר הפשרה של המערכת, תינתן להם אפשרות להגיש שוב עוד 5 הצעות לפשרה, במידה ולא הושגה פשרה שוב המערכת תציע את הצעת וכך חוזר חלילה למשך 30 יום.
                                <br/>
                                לאחר 30 יום המחלוקת נסגרת במערכת.
                            
<br /> <br />
          </MKTypography>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
