/**
=========================================================
* Otis Kit PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import { useState } from "react";
// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

// Authentication layout components
import BasicLayout from "pages/Authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";


async function postData(url = "", data = {}) {
  
  const response = await fetch(url, {
    method: "POST", 
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
     
    },
    redirect: "follow", 
    body: JSON.stringify(data), 
  });
  return response.json();
}

function Cover() {
  const [emailUser, setEmailUser] = useState("");
  const [Results, setRes] = useState("");



  const emails = (event) =>  { 
    setEmailUser(event.target.value)
  }
  const clickRes=()=>{
   
    setRes("Loading...")

    
    postData("https://conflict.co.il/handlers/lginFCon.ashx", { EmailUser: emailUser }).then((data) => {
      
    if(data.status === 1)
    {
      setRes("שלחנו אליך את הסיסמא החדשה בדואר האלקטרוני")
    }
    else
    {
    setRes("לא נמצא דואר אלקטרוני במערכת");
    }
    });

    
  }

  
 
  return (
    
    <BasicLayout image={bgImage}>
      <Card>
        <MKBox
          variant="gradient"
          bgColor="dark"
          borderRadius="lg"
          coloredShadow="dark"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MKTypography variant="h3" fontWeight="medium" color="white" mt={1}>
           איפוס סיסמא
          </MKTypography>
         </MKBox>
        <MKBox pt={4} pb={3} px={3}>
          <MKBox component="form" role="form">
            <MKBox mb={4}>
              <MKInput
                type="email"
                label="דואר אלקטרוני"
                variant="standard"
                onChange = {emails}
                fullWidth
                InputLabelProps={{ shrink: true }}
                placeholder="yourName@email.com"
              />
            </MKBox>
            <MKBox mt={6} mb={1}>
              <MKButton variant="gradient" onClick={clickRes}   color="dark"
                  fontWeight="medium" fullWidth>
                איפוס
              </MKButton>
              <MKTypography variant="button" color="text">
 <br/><br/>
                      {Results}


                    </MKTypography>
            </MKBox>
          </MKBox>
        </MKBox>
      </Card>
    </BasicLayout>
  );
}

export default Cover;
