/*
=========================================================
* Otis Kit PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Otis Kit PRO examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

function SocialAnalytics() {
  return (
    <MKBox component="section" py={6}>
      <Container>
        <Grid
          container
          item
          justifyContent="center"
          xs={12}
          lg={6}
          sx={{ mx: "auto", pb: 3, textAlign: "center" }}
        >
          <MKTypography variant="h3" color="info" textGradient>
            מרכז ההסברה
          </MKTypography>
          <MKTypography variant="h2" my={1}>
            קונפליקט, הדרך הפשוטה להגיע לפשרה
          </MKTypography>
          <MKTypography variant="body1" color="text">
          אנחנו כאן כדי לעזור לך בכל שאלה, רעיון ועוד כדי לפתור את המחלוקת שלך
          </MKTypography>
        </Grid>
        <Grid container spacing={3} sx={{ mt: 3 }}>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultInfoCard
              icon="groups"
              title="הכר את הצוות"
              description="הצוות שלנו כאן כדי לתת לך תמיכה במערכת קונפליקט, המערכת מספקת מגוון רחב של אנשי מקצוע שיכולים לעזור לך אם לא הצלחת להגיע להסדר "
              direction="center"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultInfoCard
              icon="support_agent"
              title="תמיכה 24/7"
              description="אנחנו כאן כדי לעזור לך ולענות על שאלותיך לגבי המערכת בכל עת אז שלח כמייל לכתובת info@conflict.law"
              direction="center"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} sx={{ mx: "auto" }}>
            <DefaultInfoCard
              icon="update"
              title="פיתוחי AI"
              description="אנחנו לא עוצרים בפיתוח וממשכים לפתח גרסאות מתקדמות של מודלים ליישובי מחלוקות אונליין על בסיס AI, ניתן לפנות אלינו לכל שאלה בנידון"
              direction="center"
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default SocialAnalytics;
