
// @mui material components
import Icon from "@mui/material/Icon";

// Pages
import HowItWorks  from "pages/HowItWorks";

import HelpCenter from "layouts/pages/support/help-center";
import ContactUs from "layouts/pages/support/contact-us";
// import VirtualRealityPage from "layouts/pages/extra/virtual-reality";
import About from "layouts/pages/company/About";
// Account
import SignInBasicPage from "layouts/authentication/sign-in/basic";
import SignUpCoverPage from "layouts/authentication/sign-up/cover";
import SignresetPassword from "layouts/authentication/reset-password/cover";
import SignInvatation from "layouts/authentication/sign-in/Invatation"
import Terms from "pages/Terms"
import Privacy from "pages/Privacy";
// Sections
import AboutUs from "layouts/pages/company/about-us";


const routes = [
  
  {
    name: "ראשי",
    icon: <Icon>dashboard</Icon>,
    columns: 2,
    rowsPerColumn: 2,
    collapse: [
      {
        name: "Conflict.co.il",
        collapse: [
          {
            name: "ראשי",
            route: "/pages/company/about-us",
            component: <AboutUs />,
          },
          {
            name : "אודות",
            route : "/pages/company/About",
            component: <About />,
          },
          {  
            name: "כיצד זה עובד",
            route:"/pages/company/HowitWorks",
            component:<HowItWorks/>,

          }
        ],
      },
      {
        name: "תמיכה",
        collapse: [
          {
            name: "מרכז תמיכה",
            route: "/pages/support/help-center",
            component: <HelpCenter />,
          },
          {
            name: "יצירת קשר",
            route: "/pages/support/contact-us",
            component: <ContactUs />,
          },
        
         ],
      },
           
      {
        name: "מדיניות ופרטיות",
        collapse: [
          {
            name: "תנאי שירות",
            route: "/pages/Terms",
            component: <Terms />,
          },
          {
            name: "מדיניות פרטיות",
            route : "/Pages/Policy",
            component:<Privacy />,

          },
        ],
      },
    ],
  },
  {
    name: "חשבון",
    icon: <Icon>contacts</Icon>,
    columns: 2,
    rowsPerColumn: 3,
    collapse: [
      {
        name: "כניסה למערכת ",
        collapse: [
          {
            name: "כניסה",
            route: "/authentication/sign-in/basic",
            component: <SignInBasicPage />,
          },
          {
            name: "שכחת סיסמא ?",
            route: "/authentication/resetpassword/basic",
            component: <SignresetPassword />,
          },
          
        ],
      },
      {
        name: "מוזמנים",
        collapse: [
          {
            name: "כניסה למוזמנים",
            route: "/Invitation",
            component: <SignInvatation />,
          },
         
         ],
      },
      {
        name: "רישום חדש",
        collapse: [
          {
            name: "הרשמה",
            route: "/authentication/sign-up",
            component: <SignUpCoverPage />,
          },
         
         ],
      },
      
     
     
    ],
  },

]; 

export default routes;
