/*
=========================================================
* Otis Kit PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function Information() {
  return (
    <MKBox component="section" py={6} mt={6}>
      <Container>
        <Grid container spacing={3} item xs={12} lg={8} mx="auto">
          
          <MKTypography variant="h2" mb={3}>
            אודות קונפליקט
          </MKTypography>
          <MKTypography variant="body2">
          לראשונה בעולם האינטרנט מערכת ליישוב מחלוקות כספיות, מקוונת ללא התערבות של גוף או אדם נוסף
          <br /><br />
          מערכת קונפליקט משמשת ככלי עזר ואינטגרציה בין שני צדדים אשר ביניהם מחלוקת כזו או אחרת.
                                מטרת המערכת היא להביא את שני הצדדים המסוכסכים בניהם לפתרון בדרכי נועם ושמירת האינטרסים האישיים של כל צד וצד בקונפליקט ע&quot;י שימוש במנגנון הפשרה המהירה הייחודי אשר מאפשר לכל צד להגיש הצעת פשרה לפי האינטרסים שלו.

          </MKTypography>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
