/*
=========================================================
* Otis Kit PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import { useState } from "react";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

import Container from "@mui/material/Container";

// Otis Kit PRO examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Image
import bgImage from "assets/images/office-dark.jpg";


async function postData(url = "", data = {}) {
  
  const response = await fetch(url, {
    method: "POST", 
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
     
    },
    redirect: "follow", 
    body: JSON.stringify(data), 
  });
  return response.json();
}


function ContactUs() {

  const [emailUser, setEmailUser] = useState("");
  const [UserName, setUserName] = useState("");
 const [messageSet,setMessage] = useState("");

  const [Results, setRes] = useState("");



  const emails = (event) =>  { 
    setEmailUser(event.target.value)
  }
  const userName = (event) =>  { 
    setUserName(event.target.value)
  }
  const Messages = (event) =>  { 
    setMessage(event.target.value)
  }
  
  const clickRes=()=>{
   
    setRes("Loading...");

    
    postData("https://conflict.co.il/handlers/MesSend.ashx", { EmailUser: emailUser, UserNames:UserName, Messagess:messageSet }).then((data) => {
      
    if(data.status === 1)
    {
     setRes("ההודעה נשלחה, תודה")
    }
    else
    {
    setRes("קרתה תקלה בעת שליחה ההודעה, נא צור קשר דרך דואר האלקטרוני : info@conflict.law");
    }
    });

    
  }




  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "/authentication/sign-in/basic",
          label: "Start",
          color: "dark",
        }}
        transparent
        light
      />
      <MKBox
        minHeight="50vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h2"
              color="white"
              mb={2}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              צור קשר
            </MKTypography>
            
          </Grid>
        </Container>
      </MKBox>
      
      <Grid container spacing={1} alignItems="center">
        
        <Grid
        container
          item
          justifyContent="center"
          alignItems="center"
          xs={12}
          sm={10}
          md={7}
          lg={6}
          xl={20}
          alignContent="center"
          ml={{ xs: "auto", lg: 6 }}
          mr={{ xs: "auto", lg: 6 }}
        >
          <MKBox
            bgColor="white"
            borderRadius="xl"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mt={{ xs: 20, sm: 18, md: 20 }}
            mb={{ xs: 20, sm: 18, md: 20 }}
            mx={1}
          >
            <MKBox
              variant="gradient"
              bgColor="dark"
              coloredShadow="info"
              borderRadius="lg"
              p={2}
              mx={2}
              mt={-3}
            >
              <MKTypography variant="h3" color="white">
               יצירת קשר
              </MKTypography>
            </MKBox>
            <MKBox p={3}>
              <MKTypography variant="body2" color="text" mb={3}>
              לשאלות נוספות, כולל הזדמנויות לשותפות או פעילות במערכת קונפליקט, אנא שלח דואר אלקטרוני
                info@conflict.law או צור קשר באמצעות טופס יצירת הקשר שלנו.
              </MKTypography>
              <MKBox width="100%" component="form" method="post" autocomplete="off">
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      variant="standard"
                      placeholder="שם מלא"
                      onChange={userName}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      type="email"
                      variant="standard"
                      placeholder="דואר אלקטרוני"
                      onChange={emails}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MKInput
                      variant="standard"
                      
                      placeholder="מהות ההודעה"
                      InputLabelProps={{ shrink: true }}
                      onChange={Messages}
                      multiline
                      fullWidth
                      rows={6}
                    />
                  </Grid>
                </Grid>
                <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
                  <MKButton  variant="gradient" onClick={clickRes} color="info">
                   שליחה
                  </MKButton>
              
                </Grid>
                <MKTypography variant="button" color="text">
 <br/>
                      {Results}


                    </MKTypography>
                 
              </MKBox>
            </MKBox>
          </MKBox>
        </Grid>
      </Grid>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default ContactUs;
