

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function PrivacyInfo() {
  return (
    <MKBox component="section" py={6} mt={6}>
      <Container>
        <Grid container spacing={3} item xs={12} lg={8} mx="auto">
          
          <MKTypography variant="h2" mb={3}>
        קונפליקט - מדיניות פרטיות
          </MKTypography>
          <MKTypography variant="body2">
        
     
          <br/><br/>
			<b>מבוא</b>
<br/><br/>
מערכת קונפליקט או כל אדם הפועל מטעמה, (&quot;קונפליקט&quot;, &quot;אנחנו&quot;, &quot;שלנו&quot;, &quot;אותנו&quot;) מכבדת את הפרטיות שלך. מדיניות פרטיות זו מסבירה ומפרטת את הנהלים ואופן השימוש במידע שלך במסגרת השירותים הזמינים ב www.conflict.co.il  (&quot;האתר&quot;, &quot;השירות&quot;).
<br/><br/>
על ידי שימוש באתר אתה מאשר בזאת ומסכים לנהלי הפרטיות שלנו המפורטים כאן. מדיניות פרטיות זו חלה רק על מידע שאתה מספק לנו באמצעות האתר. מדיניות פרטיות זו עשויה להתעדכן מעת לעת. במקרה של שינוי תנאים אלו, אנו נפרסם עדכון בדבר השינוי באתר או נספק לך התראה בדבר השינוי. בנוסף, נעדכן את &quot;תאריך העדכון האחרון&quot; בראשית עמוד זה. המשך השימוש באתר ו\או שירותיו לאחר העדכון בדבר השינויים מהווה הסכמה מצידך לתנאים החדשים. במידה ואינך מסכים לתנאים החדשים, עליך לחדול מכל פעילות באתר.
<br/><br/> 
השירות אינו מיועד לשימוש על ידי אנשים מתחת לגיל 18. אם אינך בן 18 שנים או יותר, אינך יכול להירשם לשירות.
<br/><br/>
למונחים במדיניות פרטיות זו תהיה משמעות זהה כפי שהוגדרה בתקנון האתר הזמין בקישור: www.conflict.co.il/pageinfo.html?Terms  . בנוסף, המונחים &quot;שימוש&quot; ו&quot;עיבוד&quot; מידע, כוללים שימוש בקבצי COOKIES במחשב, העברת מידע לצורך ניתוח סטטיסטי או אחר, ו\או שימוש במידע בכל דרך שהיא, כולל, אך לא מוגבל לאיסוף, אחסון, הערכה, שינוי, מחיקה, שימוש, שילוב, חשיפה והעברת מידע בתוך הארגון או מחוצה לו.
<br/><br/>

<b>איסוף ושימוש במידע</b>
<br/><br/>
המטרות העיקריות שלנו באיסוף המידע הן לספק ולשפר את השירות והתוכן באתר, זאת כדי לאפשר לנו לספק את השירותים באתר ולאפשר למשתמשים לנווט בקלות באתר וליהנות משירותיו.
<br/><br/>
כאשר אתה נרשם לשרות כחבר אתה נדרש לספק לנו מידע אודותיך, המאפשר זיהוי אישי ויכול לשמש ליצירת קשר או לזיהוי (&quot;מידע אישי&quot;). מידע אישי זה כולל, אך אינו מוגבל לשם, כתובת, מספר טלפון, פרטי כרטיס אשראי או דרכי חיוב אחרים, דואר אלקטרוני וכתובות דואר ביתיות ועסקיות. אנו משתמשים במידע האישי שלך על מנת לספק לך את השירות ולנהל את צרכיך באמצעות האתר. 
<br/><br/>
אנו אוספים גם מידע שאינו מזהה, אשר אתה מספק כחלק מהרישום והשימוש בשירות לדוגמא וללא הגבלה: מידע הקשור למחלוקות שתגיש באמצעות השירות, מידע הקשור לעסקאות שלך בשירות והעדפות אישיות (&quot; מידע לא מזוהה&quot;). 
<br/><br/>
לעיתים אנו נבצע הצלבות בין מידע זה לבין המידע האישי שלך על מנת לספק את השירות, לאפשר שימוש במנגנון הפשרה המהיר, ולנהל את הצרכים שלך באתר. 
<br/><br/>
מידע ללא זיהוי ייחשב חלק מהמידע האישי שלך כאשר הוא מצורף לאמצעי מזהה אחר (לדוגמא, שילוב המיקוד שלך עם כתובת הרחוב שלך) באופן שמאפשר לך להיות מזוהה. כאשר אותו מידע ללא זיהוי נלקח לבד או בשילוב רק עם מידע אחר ללא זיהוי, הוא יחשב כמידע ללא זיהוי.
<br/>
בנוסף, אנו עשויים להצליב בין מידע אישי שלך ו/או מידע לא מזוהה שלך לבין מידע שנאסף ממשתמשי קונפליקט אחרים, על מנת לספק לך חוויה טובה יותר, לשפר את איכות וערך השירות ולנתח את השימוש באתר ושירותיו. 
<br/><br/>
בנוסף, אנו עשויים לעשות שימוש במידע האישי שלך על מנת לספק לך חומרי שיווק וקידום מכירות ומידע נוסף שאנו סבורים שעשוי לעניין אותך. הנך רשאי להחליט בכל עת שאתה אינך מעוניין עוד לקבל חומרים אלו. במקרה כזה הינך מוזמן לשלוח לנו הודעה על כך בכתובת info@conflict.co.il
<br/><br/> .
כאשר אתה מבקר באתר, בין כחבר ובין כמשתמש שאינו רשום, שרתי קונפליקט שומרים מידע אשר הדפדפן שלך שולח בכל פעם שאתה מבקר באתר אינטרנט (&quot;קבצי LOG&quot; (Log Data)). נתונים אלו כוללים בין היתר מידע כגון כתובת פרוטוקול האינטרנט של מחשבך (IP), סוג הדפדפן בו אתה משתמש, דף האינטרנט בו ביקרת בטרם ביקרת בשירותינו, דפי השירות שלנו ומשך הזמן שאתה מבקר בהם, זמני גישה, ונתונים סטטיסטים אחרים. אנו משתמשים במידע זה על מנת לעקוב ולנתח את השימוש באתר ושירותיו ולשם ניהול טכני של השירות, על מנת להתאים אותו טוב יותר לצרכי המשתמשים והמבקרים באתר וכדי לשפר את חווית הגלישה באתר. 
<br/><br/>
אנו לא מתייחסים לקבצי LOG כמידע אישי, אם כי אנו עשויים לשייך קבצים אלו למידע אישי ולהצליבו למטרות שונות כפי שמצוין לעיל במידע ללא זיהוי.
<br/><br/>
<b>  קבצי Cookies ו SESSION </b>
<br/><br/>
כמו אתרים רבים, אנו משתמשים בקבצי COOKIES על מנת לאסוף מידע. קובץ COOKIE הוא קובץ נתונים קטן שאנו מעבירים לדיסק הקשיח של מחשבך לצורך רישום ומעקב (&quot;קבצי COOKIES&quot;). אנו משתמשים בקבצים אלו לשתי מטרות: ראשית, על מנת לשמור את פרטי הכניסה שלך לצורך כניסות עתידיות לשירות. שנית, אנו משתמשים בקבצי COOKIES זמניים על מנת לאפשר תכונות מסוימות באתר, לשם הבנה טובה יותר של התקשרותך עם האתר וכן על מנת לפקח על השימוש באתר ולנתב את תעבורת האינטרנט בשירות. 
<br/><br/>
בניגוד לקבצי COOKIES קבועים, קבצי COOKIES זמניים נמחקים ממחשבך כאשר אתה מתנתק מהאתר וסוגר את הדפדפן. באפשרותך לעצור את קבלת ה- COOKIES מאתרים בהם אתה מבקר על ידי שינוי הגדרות הדפדפן שלך, אך יתכן כי חלקים מסוימים באתר לא יהיו זמינים כתוצאה מכך. 
<br/><br/>
<b>שיתוף וגילוי מידע</b>
<br/><br/>
<u>
אנו עשויים לשתף ולגלות מידע כדלקמן:
</u><br/>
סכסוכים - במידה ותבחר לספק מידע, אשר עשוי לכלול מידע אישי שלך, הקשור לסכסוכים המובאים לדיון באמצעות האתר, אנו נחלוק את המידע הנוגע לסכסוך עם הצד השני לסכסוך, בהתאם לבחירות שתבצע באתר. לפיכך הנך מתבקש לשקול בזהירות את סוג המידע שתבחר לשתף עם צדדים שלישיים באמצעות האתר.
<br/><br/>
מידע מצטבר ומידע לא מזוהה - אנו עשויים לחלוק מידע מצטבר שאינו כולל מידע אישי וכן לחשוף בכל דרך אחרת מידע לא מזוהה וקבצי LOG, עם צדדים שלישיים לצורך ניתוחים תעשייתיים, יצירת פרופיל דמוגרפי ומטרות אחרות. מידע זה לא יכיל מידע אישי שלך. 
<br/><br/>
ספקי שירות - אנו רשאים להעסיק צדדים שלישים על מנת לסייע לנו בביצוע השירות, לספק את השירות בשמנו, לבצע שירותים הקשורים לשירות (לדוגמא וללא הגבלה, שירותי תחזוקה, ניהול מסדי נתונים, ניתוחי אינטרנט, אמצעי תשלום, אנשי מקצוע ליישוב סכסוכים (לדוגמא: בוררים ומגשרים) ושיפור השירות) או לסייע לנו בניתוח השימוש באתר ושירותיו. לצדדים שלישיים אלה גישה למידע האישי שלך רק לצורך ביצוע משימות אלו בשמנו והם מחויבים שלא לחשוף ו\או להשתמש בו לכל מטרה אחרת. 
<br/><br/>
אכיפה וציות לחוק - קונפליקט משתפת פעולה עם רשויות אכיפת החוק וגורמים פרטיים לשם אכיפה וציות לחוק. אנו נחשוף כל מידע אודותיך לפקידי ממשל, רשויות אכיפת החוק או גורמים פרטיים אחרים, ככל שנמצא זאת נחוץ וראוי, על פי שיקול דעתנו הבלעדי, על מנת להשיב לטענות ו\או במסגרת הליך משפטי, כולל אך לא מוגבל ל: הגנה על הרכוש והזכויות של קונפליקט ו\או על צד שלישי, הגנה על ביטחונו של הציבור, מניעה או הפסקת פעילות שאנו סבורים שמהווה ו\או עשויה לעודד פעילות לא חוקית ו\או לא מוסרית.
<br/><br/>
העברות עסקיות - קונפליקט רשאית למכור, להעביר או לשתף בצורה אחרת את כל נכסיה או חלקם, ובכלל זה המידע האישי שלך, במקרה של מיזוג, רכישה, ארגון מחדש, מכירת נכסים או פשיטת רגל.
<br/><br/>
<b> שינוי או מחיקת תוכן של חברים
</b><br/><br/>
יש באפשרות החברים לבחון, לעדכן, לתקן או למחוק את המידע האישי בפרופיל הרישום שלהם על ידי פנייה אלינו. במידע ותבחר למחוק מידע זה לחלוטין, חשבונך לא יהיה פעיל. במידה ואתה מעוניין לעשות כן, אנא פנה אלינו בכתובת info@conflict.co.il בצירוף בקשה למחיקת המידע האישי שלך מבסיס הנתונים שלנו. אנו נעשה מאמצים סבירים, מבחינה מסחרית, למילוי בקשתך בהקדם האפשרי. יש באפשרותנו לשמור עותק ארכיוני של הרשומות שלך, כפי שנדרש על פי כל חוק או לצורך מטרות עסקיות לגיטימיות.
<br/><br/>
במידה והצדדים הגיעו להסכמה והסכסוך בא על פתרונו ו\או במידה והוחלט שאין הסכמה בין הצדדים, אנו נשלח את המידע הנוגע לפרטי הסכסוך אל הצדדים לסכסוך באמצעות דואר אלקטרוני כפי שנמסר לנו ממך. אנו נשמור מידע זה בבסיס הנתונים שלנו למשך 3 (שלושה) חודשים ולאחריהם הוא ימחק.
<br/><br/>
<b>אבטחה </b>
<br/><br/>
קונפליקט מעוניינת לשמור על המידע האישי שלך. אנו נוקטים באמצעי אבטחה פיזיים, וטכנולוגים סבירים ועושים את מירב המאמצים על מנת שיספקו הגנה טובה למידע השמור. מידע אישי רגיש כגון מספר כרטיס אשראי, שאלת ביטחון חשאית, וכו&apos;) לא ישמר באתר אלא יועבר ישירות לחברת הסליקה איזי קארד . אנו שואפים להבטיח את ביטחון המידע שלך, ומייעצים לך, בנוסף למאמצים שלנו, להישאר ערני לאיומי אבטחה שונים ברשת. 
במידה ותתגלה פרצת אבטחה באתר, אנו ניידע אותך באמצעות דואר אלקטרוני ו\או פרסום בולט באתר בהקדם האפשרי.
<br/><br/>
<b>העברות בינלאומית</b>
<br/><br/>
אנו עשויים להעביר ו\או לשמור את המידע האישי אשר אתה מוסר לנו במחשבים הממוקמים באזורים מחוץ למדינת ישראל. חוקי הפרטיות באזורים אלו עשויים לחייב אותנו לקבל ממך הסכמה מפורשת לכל  העברה שכזו. בהיותך משתמש ומוסר פרטים באתר, הנך נותן לנו בזאת במפורש את אישורך להעברת ו\או שמירת מידע באזורים אלו.
<br/><br/>
<b>קישורים בקונפליקט </b>
<br/><br/>

השירות שלנו מכיל קישורים לאתרי אינטרנט אחרים. אם תבחר ללחוץ על קישור של צד שלישי, תופנה לאתר האינטרנט של הצד השלישי. העובדה שאנחנו מקושרים לאתר אינטרנט אינה מהווה המלצה, אישור או ייצוג של השותפות שלנו עימם, ואין בה כדי לאשר את מדיניות הפרטיות שלהם, ו\או אבטחת מידע שלהם ו\או הנהלים שלהם בנושא. אין לנו שליטה על אתרי אינטרנט של צד שלישי ואתרים אלה עשויים להשתמש בקבצי COOKIES משלהם במחשבך, לאסוף נתונים או לבקש ממך מידע אישי. לאתרים אלו כללים שונים בנוגע לשימוש ו\או הגילוי של המידע האישי שאתה מוסר להם, ואנו ממליצים לקרוא את מדיניות הפרטיות שלהם.

<br/><br/>
<b>צור קשר </b>
<br/><br/>
אם יש לך שאלות כלשהן בנוגע למדיניות זו או בכל הערה, הנך מוזמן ליצור קשר עם קונפליקט בדואר אלקטרוני בכתובת:info@conflict.co.il
<br/><br/><br/><br/>
  
         
          </MKTypography>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default PrivacyInfo;
