/*
=========================================================
* Otis Kit PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Otis Kit PRO examples
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";

// Images
import team1 from "assets/images/team-5.jpg";
import team2 from "assets/images/bruce-mars.jpg";
import team3 from "assets/images/ivana-squares.jpg";
import team4 from "assets/images/ivana-square.jpg";

function Team() {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="white">
           גישור אונליין
            </MKTypography>
            <MKTypography variant="body2" color="white" opacity={0.8}>
              מגוון פתרונות להליך הפשרה במערכת קונפליקט
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team1}
                name="חסכון בזמן"
                position={{ color: "info", label: " " }}
                description="חסכון בזמן, בירוקרטיה, אמצעים משפטיים ועלויות נלוות."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team2}
                name="אלגוריתמים"
                position={{ color: "info", label: " " }}
                description="פיתוח אלגוריתמים ייחודיים ליישוב מחלוקות בין הצדדים המבוססות על האינטרסים של שני הצדדים"
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={team3}
                name="לוגיקה"
                position={{ color: "info", label: " " }}
                description="החלטת המערכת מבוססת על הצעות שני הצדדים והתנהלותם במחלוקת תוך שמירה על האינטרסים האישיים בין הצדדים"
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={team4}
                name="פשוט להתפשר"
                position={{ color: "info", label: " " }}
                description="השימוש במערכת בשלב זה הוא בחינם, אז למה לא להתפשר ולסיים את הקונפליקט שלך עוד היום"
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Team;
