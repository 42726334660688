/**
=========================================================
* Otis Kit PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import Checkbox from "@mui/material/Checkbox";


// Authentication pages components
import BasicLayout from "pages/Authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/CompImg.jpg";
import { debounce } from "@mui/material";


async function postData(url = "", data = {}) {
  
  const response = await fetch(url, {
    method: "POST", 
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
     
    },
    redirect: "follow", 
    body: JSON.stringify(data), 
  });
  return response.json();
}

function invitation() {

 const [emailUser, setEmailUser] = useState("");
 const [passer, setPassr] = useState("");
 const [Fullyname, setNames] = useState("");
 const [emailUser2, setPEmailUser2] = useState("");
 const [isChecked, setIsChecked] = useState(false);
 const [CodeReg, setConReg] = useState("");
 const [Rescheck, SetRescheck] = useState("1");

 const [ShowF2, setshow2] = useState("none");
 const [ShowF3, setShow3] = useState("none");
 const [Results, setRes] = useState("");
 

 
  const [buttonSet,setButton] = useState("block");
  const emails = (event) =>  { 
    setEmailUser(event.target.value)
}
const Passr = (event) =>  { 
setPassr(event.target.value)
}
const fullName = (event) =>  {
 setNames(event.target.value)
}
const emails2 = (event) =>  { 
  setPEmailUser2(event.target.value)
}
const checkHandler = () => {
setIsChecked(!isChecked)
}
const CodeRegLog=(event)=>{
setConReg(event.target.value)

}

let ParamterIn=""; 
  const searchParam = new URLSearchParams(document.location.search);
  if(searchParam !== null)
   ParamterIn=searchParam.get('st');

  if(ParamterIn !== "" )
  {
    
       postData("https://conflict.co.il/handlers/LogInv.ashx", { strGet: ParamterIn,emails:emailUser,passtring:passer }).then((data) => {
    
    if(data.Mes_To > 0 )  
    {
      
      ParamterIn=searchParam.get('st');
      const constUrl="/authentication/sign-in/basic?st="
   
     window.location.href=constUrl+ParamterIn;
    }
      if(ShowF3 === "block")
      {setshow2("none");}
      else
      {setshow2("block");}
    
     
    });
    }
    
 

     
  
const clickRegLogin=()=>{
  ParamterIn=searchParam.get('st');
  const constUrl="/authentication/sign-in/basic?st="

  window.location.href=constUrl+ParamterIn;

}

  const clickResNew=()=>{
   
    const searchParams = new URLSearchParams(document.location.search)
    const ParamtersIn=searchParams.get('st');
    
    setRes("Loading...");

    if(ParamtersIn === null )
    {
      setRes("המערכת לא הצליחת למצוא הזמנה לפשרה ");
     
    }
  
if(!Fullyname)
{
  setRes("נא עדכן שם פרטי")
  SetRescheck(0)
}

 if(!emailUser.includes("@"))
   {
    setRes("דואר אלקטרוני אינו תקני")
    SetRescheck(0)
   }
if(emailUser!==emailUser2)
{ 
setRes("אין התאמה בין תיבות הדואר האלקטרוני")
SetRescheck(0)
}

if(!isChecked){
 
setRes("יש לאשר תקנון מערכת קונפליקט")
SetRescheck(0)
}


if(Rescheck === "1" )
{
        
   postData("https://conflict.co.il/handlers/lginNinv.ashx", { strGet: ParamtersIn,EmailUser: emailUser,Pascode: passer,FullName:Fullyname }).then((data) => {
      
    if(data.status === 1)
    {
     
      setshow2("none");
      setShow3("block");
      setRes("");
    }
    else
    {
    setRes("קרתה תקלה בעת הרישום, נא צור קשר עם מחלקת התמיכה");
    }

    });

  }
  }
  const clickRes3 = debounce(clickResNew, 300) ;
  
  const clickRegFinish =()=>{
    setButton("none")
    setRes("Loading...")
    const searchParams = new URLSearchParams(document.location.search)
    const ParamtersIn=searchParams.get('st');
    postData("https://conflict.co.il//handlers/lginRConI.ashx", { CodeRegIn: CodeReg,EmailUser:emailUser, strGet:ParamtersIn }).then((data) => {
      
    if(data.status === 1)
    {
      window.location.href="https://conflict.co.il/Arena/home.html";
    
    }
    else
    {
    setRes("The code entered is incorrect");
    setButton("block");
    }
    });
  }
  const clickRes4 = debounce(clickRegFinish, 300) ;

  

  


  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MKBox
          variant="gradient"
          bgColor="dark"
          borderRadius="lg"
          coloredShadow="dark"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            כניסה למוזמנים
          </MKTypography>
         
        </MKBox>
           
        
        <MKBox p={3} display={ShowF2}>
        <MKBox component="form" role="form">
            <MKBox mb={2}>
              <MKInput type="text" onChange={fullName} label="שם מלא" fullWidth />
            </MKBox>
            <MKBox mb={2}>
              <MKInput type="email" onChange={emails} label="דואר אלקטרוני" fullWidth />
            </MKBox>
            <MKBox mb={2}>
              <MKInput type="email" onChange={emails2} label="התאמה דואר אלקטרוני" fullWidth />
            </MKBox>
            <MKBox mb={2}>
              <MKInput type="password" onChange={Passr} label="סיסמא" fullWidth />
            </MKBox>
            <MKBox display="flex" alignItems="center" ml={-1}>
              <Checkbox onChange={checkHandler} />
              <MKTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                  &nbsp; מאשר &nbsp;&nbsp;
              </MKTypography>
              <MKTypography
                component="a"
                href="#"
                variant="button"
                fontWeight="bold"
                color="info"
                textGradient
              >
                  תקנון מערכת קונפליקט 
              </MKTypography>
            </MKBox>
            <MKBox mt={3} mb={1}>
              <MKButton variant="gradient" display={buttonSet} onClick={clickRes3} color="dark" fullWidth>
רישום
              </MKButton>
            </MKBox>            
            <MKBox mt={3} mb={1} textAlign="center">
            <MKTypography variant="button" color="text">
           {Results}
            <br /><br /><hr/>
            <MKTypography variant="button" color="text">
                האם ברשותך חשבון קיים ?{" "}
                <MKTypography
                  component={Link}
                 to="#"
                  variant="button"
                  color="dark"
                  onClick={clickRegLogin}
                  fontWeight="medium"
                  textGradient
                >
                  כניסה
                </MKTypography>
              </MKTypography>
            </MKTypography>
            </MKBox>
          </MKBox>
      </MKBox>
      <MKBox p={3} display={ShowF3}>
      
      <MKTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
             נא עדכן את הקוד שנשלח לך בדואר האלקטרוני, <br/> * נא לא לסגור את החלון<br/><br/>
              </MKTypography>
             
              
            <MKBox mb={2}>
              <MKInput type="number" onChange={CodeRegLog} label="Code" fullWidth />
            </MKBox>
            <MKButton variant="gradient" display={buttonSet} onClick={clickRes4} color="dark" fullWidth>
                כניסה לחשבון
              </MKButton>
              <MKTypography variant="button" color="text">
           {Results}
            <br /><br /><hr/>
            </MKTypography>
      </MKBox>
              </Card>
    </BasicLayout>
  );
}

export default invitation;
