/**
=========================================================
* Otis Kit PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import { debounce } from "@mui/material";
import MKButton from "components/MKButton";

// Authentication pages components
import BasicLayout from "pages/Authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

async function postData(url = "", data = {}) {
  
  const response = await fetch(url, {
    method: "POST", 
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
     
    },
    redirect: "follow", 
    body: JSON.stringify(data), 
  });
  return response.json();
}


function SignInBasic() {
  
  const [emailUser, setEmailUser] = useState("");
  const [passer, setPassr] = useState("");
  const [Results, setRes] = useState("");
  const [ShowF2, setshow2] = useState("block");
  const [ShowF3, setShow3] = useState("none");
  const [CodeReg, setConReg] = useState("");


  const emails = (event) =>  { 
    setEmailUser(event.target.value)
  }
  const Passr = (event) =>  { 
    setPassr(event.target.value)
  }
  const CodeRegLog=(event)=>{
    setConReg(event.target.value)
    
    }
  const clickRes=()=>{
   
    setRes("Loading...")
    let Param="0,0";
    const searchParams = new URLSearchParams(document.location.search)
    const ParamtersIn=searchParams.get('st');
    if(ParamtersIn !== null)
    Param=ParamtersIn;

    postData("https://conflict.co.il/handlers/lginCon.ashx", { EmailUser: emailUser,Pascode: passer,strGet:Param }).then((data) => {
      
    if(data.status === 1 && Param === "0,0")
    {
    
      window.location.href="https://conflict.co.il/Arena/home.html";
      
    }
    
    if(data.status === 1 && ParamtersIn !== null)
    {
setshow2("none");
setShow3("block");


    }    
    if(data.status === 0)
    {
    setRes("שם המשתמש או הסיסמא שגויים");
    }
    });

    
  }
  const clickRegFinish =()=>{
    setRes("Loading...")
    const searchParams = new URLSearchParams(document.location.search)
    const ParamtersIn=searchParams.get('st');
    postData("https://conflict.co.il/handlers/lginRConI.ashx", { CodeRegIn: CodeReg,EmailUser:emailUser, strGet:ParamtersIn }).then((data) => {
      
    if(data.status === 1)
    {
      window.location.href="https://conflict.co.il/Arena/home.html";
    
    }
    else
    {
    setRes("הקוד שהוקלד שגוי");
    
    }
    });
  }
  const clickRes4 = debounce(clickRegFinish, 300) ;
  


  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MKBox
          variant="gradient"
          bgColor="dark"
          borderRadius="lg"
          coloredShadow="dark"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            כניסה לקונפליקט 
          </MKTypography>
         
        </MKBox>
          
          <MKBox pt={4} pb={3} px={3} display={ShowF2}>
          <MKBox component="form" role="form">
            <MKBox mb={2}>
              <MKInput type="email" onChange={emails} label="דואר אלקטרוני" fullWidth />
            </MKBox>
            <MKBox mb={2}>
              <MKInput type="password" onChange={Passr} label="סיסמא" refs="password" fullWidth />
            </MKBox>
           
            <MKBox mt={4} mb={1}>
              <MKButton variant="gradient" onClick={clickRes} color="dark" fullWidth>
                כניסה
              </MKButton>
            </MKBox>
                    <MKBox mt={3} mb={1} textAlign="center">

                    <MKTypography variant="button" color="text">
 
                      {Results}


                    </MKTypography>
                    <br /><br /><hr/>
              <MKTypography variant="button" color="text">
               אין לך חשבון ?{" "}
                <MKTypography
                  component={Link}
                  to="/authentication/sign-up"
                  variant="button"
                  color="dark"
                  fontWeight="medium"
                  textGradient
                >
                  רישום
                </MKTypography>
              </MKTypography>
            </MKBox>
          </MKBox>
        </MKBox>
        <MKBox p={3} display={ShowF3}>
      
      <MKTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
             נא לעדכן את הקוד שנשלח בדואר האלקטרוני, <br/> * לא לסגור את החלון<br/><br/>
              </MKTypography>
             
              
            <MKBox mb={2}>
              <MKInput type="number" onChange={CodeRegLog} label="קוד גישה" fullWidth />
            </MKBox>
            <MKButton variant="gradient"  onClick={clickRes4} color="dark" fullWidth>
                כניסה
              </MKButton>
              <MKTypography variant="button" color="text">
           {Results}
            <br /><br /><hr/>
            </MKTypography>
      </MKBox>
              </Card>
    </BasicLayout>
  );
}

export default SignInBasic;
