/**
=========================================================
* Otis Kit PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";
import { useState } from "react";
// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

// Authentication layout components
// import CoverLayout from "pages/Authentication/components/CoverLayout";
import BasicLayout from "pages/Authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-rental.jpeg";



async function postData(url = "", data = {}) {
  
  const response = await fetch(url, {
    method: "POST", 
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
     
    },
    redirect: "follow", 
    body: JSON.stringify(data), 
  });
  return response.json();
}

function Cover() {
  const [emailUser, setEmailUser] = useState("");
  const [passer, setPassr] = useState("");
  const [Fullyname, setNames] = useState("");
  const [emailUser2, setPEmailUser2] = useState("");
  const [isChecked, setIsChecked] = useState(false)
  const [Results, setRes] = useState("");
  const [Rescheck, SetRescheck] = useState("1");
  const [ShowF1, setshow1] = useState("block");
  const [ShowF2, setshow2] = useState("none");
  const [CodeReg, setConReg] = useState("");
  const [buttonSet,setButton] = useState("block");



  const emails = (event) =>  { 
        setEmailUser(event.target.value)
  }
  const Passr = (event) =>  { 
    setPassr(event.target.value)
  }
  const fullName = (event) =>  {
     setNames(event.target.value)
  }
  const emails2 = (event) =>  { 
      setPEmailUser2(event.target.value)
  }
  const checkHandler = () => {
    setIsChecked(!isChecked)
  }
  const CodeRegLog=(event)=>{
setConReg(event.target.value)

  }
  
  const clickRes=()=>{

if(!Fullyname)
{
  setRes("נא לעדכן שם פרטי")
  SetRescheck(0)
}

 if(!emailUser.includes("@"))
   {
    setRes("דואר אלקטרוני אינו תקני")
    SetRescheck(0)
   }
if(emailUser!==emailUser2)
{ 
setRes("אין התאמה בין המיילים")
SetRescheck(0)
}

if(!isChecked){
 
setRes("יש צורך לאשר את התקנון המערכת")
SetRescheck(0)
}


if(Rescheck === "1" )
 {
  setButton("none")
    setRes("Loading...")
    postData("https://conflict.co.il//handlers/lginNCon.ashx", { EmailUser: emailUser,Pascode: passer,FullName:Fullyname }).then((data) => {
      
    if(data.status === 1)
    {
      setshow1("none");
      setshow2("block");
      setRes("");
      setButton("block")
      
    }
    else 
    {
    setRes("קיים משתמש במערכת, נא לבצע כניסה ");
    setButton("block")  
  }
    });
     
}
  }
  const clickRegFinish =()=>{
    setButton("none")
    setRes("טעינה...")
    postData("https://conflict.co.il/handlers/lginRCon.ashx", { CodeRegIn: CodeReg,EmailUser:emailUser }).then((data) => {
      
    if(data.status === 1)
    {
      window.location.href="https://conflict.co.il/Arena/home.html";
      
    }
    else
    {
    setRes("הקוד שהוקלד אינו תקני");
    setButton("block")
    }
    });
  }


  return (
    <BasicLayout image={bgImage}>
   
      <Card>
        <MKBox
          variant="gradient"
          bgColor="dark"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
          הרשמה לקונפליקט
          </MKTypography>
          <MKTypography display="block" variant="button" color="white" my={1}>
            נא עדכן את שמך והדואר האלקטרוני
          </MKTypography>
        </MKBox>
    <MKBox p={3} display={ShowF1}>
          <MKBox component="form" role="form">
            <MKBox mb={2}>
              <MKInput type="text" onChange={fullName} label="שם מלא" fullWidth />
            </MKBox>
            <MKBox mb={2}>
              <MKInput type="email" onChange={emails} label="דואר אלקטרוני" fullWidth />
            </MKBox>
            <MKBox mb={2}>
              <MKInput type="email" onChange={emails2} label="דואר אלקטרוני התאמה " fullWidth />
            </MKBox>
            <MKBox mb={2}>
              <MKInput type="password" onChange={Passr} label="סיסמא" fullWidth />
            </MKBox>
            <MKBox display="flex" alignItems="center" ml={-1}>
              <Checkbox onChange={checkHandler} />
              <MKTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp; מאשר &nbsp;&nbsp;
              </MKTypography>
              <MKTypography
                component="a"
                href="#"
                variant="button"
                fontWeight="bold"
                color="info"
                textGradient
              >
                תקנון מערכת קונפליקט 
              </MKTypography>
            </MKBox>
            <MKBox mt={3} mb={1}>
              <MKButton variant="gradient" display={buttonSet} onClick={clickRes} color="dark" fullWidth>
               רישום למערכת
              </MKButton>
            </MKBox>            
            <MKBox mt={3} mb={1} textAlign="center">
            <MKTypography variant="button" color="text">
           {Results}
            <br /><br /><hr/>
            </MKTypography>

              <MKTypography variant="button" color="text">
                האם ברשותך חשבון במערכת ?{" "}
                <MKTypography
                  component={Link}
                  to="/authentication/sign-in/basic"
                  variant="button"
                  color="dark"
                  fontWeight="medium"
                  textGradient
                >
                  כניסה
                </MKTypography>
              </MKTypography>
            </MKBox>
          </MKBox>
        </MKBox>
    <MKBox p={3} display={ShowF2}>
      
      <MKTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
              נא לעדכן את הקוד שנשלח בדואר האלקטרוני <br/> * נא לא לסגור חלון זה<br/><br/>
              </MKTypography>
             
              
            <MKBox mb={2}>
              <MKInput type="number" onChange={CodeRegLog} label="קוד גישה" fullWidth />
            </MKBox>
            <MKButton variant="gradient" display={buttonSet} onClick={clickRegFinish} color="dark" fullWidth>
כניסה              </MKButton>
              <MKTypography variant="button" color="text">
           {Results}
            <br /><br /><hr/>
            </MKTypography>
      </MKBox>
      </Card>
      
    </BasicLayout>

  );
}

export default Cover;
