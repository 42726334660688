/*
=========================================================
* Otis Kit PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

function LifetimeMembership() {
  return (
    <MKBox component="section" py={{ xs: 12, lg: 5 }}>
      <Container>
        <Grid container item xs={12}>
          <Card sx={{ width: "100%" }}>
            <Grid container alignItems="center">
              <Grid item xs={12} lg={8}>
                <MKBox py={3} px={4}>
                  <MKTypography variant="h3" mb={1}>
                   יתרונות המערכת
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                בנוסף ליתרונות המנגנוני הפשרה, בקונפליקט מגוון של יתרונות נוספים לפתרון המחלוקת
                  </MKTypography>
                  <Grid container item xs={12} lg={3} sx={{ mt: 6, mb: 1 }}>
                    <MKTypography variant="h6">יתרונות</MKTypography>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <MKBox display="flex" py={1} pr={1} lineHeight={0}>
                        <MKTypography variant="body1" color="dark">
                          <Icon sx={{ fontWeight: "bold" }}>done</Icon>
                        </MKTypography>
                        <MKTypography variant="body2" color="text" fontWeight="regular" pl={1}>
                         חסכון בזמן 
                        </MKTypography>
                      </MKBox>
                      <MKBox display="flex" py={1} pr={1} lineHeight={0}>
                        <MKTypography variant="body1" color="dark">
                          <Icon sx={{ fontWeight: "bold" }}>done</Icon>
                        </MKTypography>
                        <MKTypography variant="body2" color="text" fontWeight="regular" pl={1}>
                          שמירת האינטרס האישי
                        </MKTypography>
                      </MKBox>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKBox display="flex" py={1} pr={1} lineHeight={0}>
                        <MKTypography variant="body1" color="dark">
                          <Icon sx={{ fontWeight: "bold" }}>done</Icon>
                        </MKTypography>
                        <MKTypography variant="body2" color="text" fontWeight="regular" pl={1}>
                       מערכת אישית קלה לשימוש ליישוב המחלוקת
                        </MKTypography>
                      </MKBox>
                      <MKBox display="flex" py={1} pr={1} lineHeight={0}>
                        <MKTypography variant="body1" color="dark">
                          <Icon sx={{ fontWeight: "bold" }}>done</Icon>
                        </MKTypography>
                        <MKTypography variant="body2" color="text" fontWeight="regular" pl={1}>
                       ללא התערבות אנושית או גוף 3
                                                </MKTypography>
                      </MKBox>
                    </Grid>
                  </Grid>
                </MKBox>
              </Grid>
              <Grid item xs={12} lg={4}>
                <MKBox p={3} display='none' textAlign="center">
                  
                <MKTypography  variant="h1">
                    <MKBox component="small">$</MKBox>0
                  </MKTypography>
                  <MKTypography variant="h6" color="text" fontWeight="regular" pl={1}>
                     * After the second offer for unlimited offers to 30 days per case
                        </MKTypography>
                  <MKButton variant="gradient" href="/authentication/sign-in/basic" color="error" size="large" sx={{ my: 2 }}>
                   הגש הצעה לפשרה
                  </MKButton>
                  
                </MKBox>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default LifetimeMembership;
