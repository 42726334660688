/*
=========================================================
* Otis Kit PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import ClickStart from "assets/images/ClickStart.jpg";

// Otis Kit PRO components
import MKBox from "components/MKBox";

// Otis Kit PRO examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

function Information() {
  return (
    <MKBox component="section" py={12} >
      <Container>
        <Grid container spacing={5} alignItems="center">
          <Grid item xs={12} lg={8}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="apps"
                    title="אינטגרציה"
                    description="קונפליקט הינו כלי אינטגרציה בין שני צדדים המאפשר לצדדים להגיש הצעות לפשרה ללא התערבות אנושית"
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="account_balance"
                    title="מודלים - ODR"
                    description="קונפליקט הינו כלי ODR אשר מספק מגוון פתרונות ליישוב מחלקות כגון חובות כספיים, מחלקות פיננסים, רכישות  וגירושים כל זה בעזרת מודלים טכנולוגים של ODR"
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="apps"
                    title="שמירת האינטרס האישי"
                    description="שמירת האינטרס האישי של כל צד במחלוקת, ההצעות נשמרות במערכת והצדדים אינם מודעים להצעות של הצד השני"
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="3p"
                    title="הגשה הצעה"
                    description="קונפליקט מאפשר לך להגיש הצעות פשרה מהערך המינימלי ועד לערך המקסימלי שהנך מעוניין להתפשר "
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={ClickStart}
              title="הדרך הפשוטה להגיע לפשרה"
              description="קונפליקט מאפשר לך לפתור את המחלוקת ולקבל את התוצאות הטובות ביותר על בסיס ההצעתך כל זאת ללא התערבות אנושית"
              action={{
                type: "internal",
                route: "/authentication/sign-up",
                color: "info",
                label: "יישוב מחלוקת",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
